.wallet-adapter-dropdown {
    flex:1;
}

.wallet-adapter-button {
    width: 100%;
}

.wallet-adapter-button-trigger {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    height: 40px;
}

.wallet-adapter-button:not([disabled]):hover.wallet-adapter-button-trigger {
    background-color: hsl(258deg 58% 32%);
}